import React, { FC } from 'react'
import { Gallery } from './lightgallery/lightgallery'
import styles from './index.module.scss'
import { FadeInWhenVisible } from '../FadeInWhenVisible'
import { JoinOurTeam } from './JoinOurTeam'
import { useMediaQuery } from '../../hooks/useMediaQuery'

export interface IHomeVideoLightboxProps {
  videoLink?: string
  plusIcon?: boolean
  textPlayBtn?: string
  customCtaText?: string
  customCtaLink?: string
  customLinkTarget?: string
  customClass?: string
  fade?: boolean
  hover?: boolean
  lightBoxStatus?(status: boolean): void
}

export const HomeVideoLightbox: FC<IHomeVideoLightboxProps> = props => {
  const { videoLink, textPlayBtn } = props
  const isMobile = useMediaQuery('(max-width: 1023px)')

  return (
    <div className={styles.HomeVideoLightBoxContainer}>
      <div className={styles.btnTextWrapper}>
        {!isMobile ? (
          <FadeInWhenVisible
            animation="easySliderBottomToTopFadeIn"
            transition={{ duration: 0.5, delay: 2.5 }}
          >
            <Gallery videoLink={videoLink} {...props} />
          </FadeInWhenVisible>
        ) : (
          <>
            <Gallery videoLink={videoLink} {...props} />
          </>
        )}
        <FadeInWhenVisible
          animation="easySliderBottomToTopFadeIn"
          transition={{ duration: 0.5, delay: 3 }}
          className={styles.lightboxTextWrapper}
        >
          <div className={styles.textWrapper}>{textPlayBtn}</div>
        </FadeInWhenVisible>

        {!isMobile ? (
          <FadeInWhenVisible
            animation="easySliderBottomToTopFadeIn"
            transition={{ duration: 0.5, delay: 3 }}
          >
            <JoinOurTeam />
          </FadeInWhenVisible>
        ) : (
          <>
            <JoinOurTeam />
          </>
        )}
      </div>
    </div>
  )
}

export default HomeVideoLightbox
