/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import LightGallery from 'lightgallery/react'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'
import { IHomeVideoLightboxProps } from '..'

import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-video.css'
import styles from './lightgallery.module.scss'

import { FadeInWhenVisible } from '../../FadeInWhenVisible'
import { BtnPlay } from '../btnPlay/btnPlay'
import PlusButton from '../../SectionGallery/PlusButton'
import classnames from 'classnames'
import { useMediaQuery } from '../../../hooks/useMediaQuery'

export const Gallery = (props: IHomeVideoLightboxProps) => {
  const { videoLink, plusIcon, customCtaText, customCtaLink, customLinkTarget, customClass, hover } = props
  
  const isMobile = useMediaQuery('(max-width: 1023px)')

  const renderCustomCtaButton = customCtaText !== '' ? `<div class="light-gallery-link"><a class="video-text" href='${customCtaLink}' target='${customLinkTarget}'>${customCtaText}</a><div class="bottom-border"></div></div>` : '<div></div>'

  return (
    <div className={classnames(styles.galleryContainer, [styles[`${customClass}`]])}>
      <LightGallery
        addClass={styles.galleryWrapper}
        plugins={[lgZoom, lgVideo]}
        mode="lg-fade"
        speed={80}
        startAnimationDuration={10}
        enableDrag={false}
        enableSwipe={false}
        hideControlOnEnd={true}
        download={false}
        loadYouTubePoster={false}
        youTubePlayerParams={{
          autoplay: 1,
          modestbranding: 1,
          showinfo: 0,
          controls: 1,
          allowfullscreen: 1,
          rel: 0,
          mute:0,
        }}
        licenseKey={process.env.NEXT_PUBLIC_LIGHTGALLERY_LICENSE_KEY}
      >
        <button
          aria-label={'play video'}
          className={styles.button}
          data-lg-size="1280-720"
          data-src={videoLink}
          data-sub-html={customCtaText !== undefined ? renderCustomCtaButton : ''}
        >
          {!isMobile ? (
            <FadeInWhenVisible animation="easyFadeInStill" delay={0.5}>
              {plusIcon ? <PlusButton/> : <BtnPlay hover={hover}/>}
            </FadeInWhenVisible>
          ) : (
            <>
              {plusIcon ? <PlusButton/> : <BtnPlay hover={hover}/>}
            </>
          )}
        </button>
      </LightGallery>
    </div>
  )
}
