import React from 'react'
import classes from './plusButton.module.scss'

export default function PlusButton () {
  return (
    <div className={classes.buttonContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14.496" height="14.496" viewBox="0 0 14.496 14.496">
        <g id="Group_1875" data-name="Group 1875" transform="translate(-1314.679 -6927.672)">
          <g id="Group_2400" data-name="Group 2400" transform="translate(1434.093 6731.337) rotate(90)">
            <path id="Path_5147" data-name="Path 5147" d="M193.787,153.514c7.7-8.043,2.875-3.2,5.84-6.2,1.443-1.464,2.1-1.966,3.587-3.392,1.569-1.5,1.591-1.172,3.14-2.681a.4.4,0,0,0-.458-.63h0c-1.854,1.071-1.883,1.062-3.672,2.249a18.394,18.394,0,0,0-3.035,2.427c-3.225,2.8-6.184,6.465-6.184,7.583S193.787,153.514,193.787,153.514Z" transform="translate(3.83 -34.91)" fill="#fff" stroke="#fff" strokeWidth="1"/>
          </g>
          <g id="Group_2425" data-name="Group 2425" transform="translate(1118.344 6822.724)">
            <path id="Path_5147-2" data-name="Path 5147" d="M193.787,153.514c7.7-8.043,2.875-3.2,5.84-6.2,1.443-1.464,2.1-1.966,3.587-3.392,1.569-1.5,1.591-1.172,3.14-2.681a.4.4,0,0,0-.458-.63h0c-1.854,1.071-1.883,1.062-3.672,2.249a18.394,18.394,0,0,0-3.035,2.427c-3.225,2.8-6.184,6.465-6.184,7.583S193.787,153.514,193.787,153.514Z" transform="translate(3.83 -34.91)" fill="#fff" stroke="#fff" strokeWidth="1"/>
          </g>
        </g>
      </svg>
    </div>
  )
}
