import React from 'react'
import AtomixIcon from '../../../components/icons/AtomixIcon'
import classes from './joinOurTeam.module.scss'


export const JoinOurTeam = () => {
  return (
    <div className={classes['join-our-team-cta']}>
      <div className={classes['join-our-team-cta__title']}>
        <AtomixIcon className={classes['join-our-team-cta__image']} iconCode={'uparrow'} />
        <span>{'Join our team'}</span>
      </div>
    </div>
  )
}

export default JoinOurTeam
